import React, { useState, useRef, useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import styles from "./styles.module.css"
import UploadFileIcon from '@mui/icons-material/UploadFile';
export const Top = () => {
  const [models, setModels] = useState([]);

  const calculateDimensions = (geometry) => {
    geometry.computeBoundingBox();
    const boundingBox = geometry.boundingBox;
    const width = (boundingBox.max.x - boundingBox.min.x).toFixed(2);
    const height = (boundingBox.max.y - boundingBox.min.y).toFixed(2);
    const depth = (boundingBox.max.z - boundingBox.min.z).toFixed(2);
    return({ width, height, depth });
  };

  const calculateVolume = (geometry, name) => {
    const dimensions = calculateDimensions(geometry)
    const positionAttribute = geometry.getAttribute('position');
    let volume = 0;
    function signedVolumeOfTriangle(p1, p2, p3) {
        return p1.dot(p2.cross(p3)) / 6.0;
    }
    // すべての三角形に対してループ
    for (let i = 0; i < positionAttribute.count; i += 3) {
        // 各三角形の頂点を取得
        const vA = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
        const vB = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 1);
        const vC = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 2);

        // 四面体の体積を計算
        volume += signedVolumeOfTriangle(vA, vB, vC);
    }
    setModels(prev => [
      ...prev,
      { name, volume, dimensions, quantity: 1, scale: 1 }
    ])
}

  const handleFileChange = (files) => {
    if (files.length) {
        Array.from(files).forEach(file => {
            const url = URL.createObjectURL(file);
            const fileType = file.name.split('.').pop();
            let loader
                if (fileType === 'stl') {
                    loader = new STLLoader();
                    loader.load(url, (object) => {
                        calculateVolume(object, file.name)
                    });
                } else if (fileType === 'obj') {
                  loader = new OBJLoader();
                  loader.load(url, (object) => {
                    object.traverse((child) => {
                        if (child.isMesh) {
                            calculateVolume(child.geometry, file.name)
                        }
                    });
                  })
                }
        });
        removeFilesFromInput()
    }
  };
  const price = (volume) => {
    if (volume === 0) return 0;

    const basePrice = 5000;
    const interval = 50;

    let totalPrice = basePrice;

    if(volume < 50) return basePrice
    const calculateIntervalPrice = (startVolume, endVolume, slope) => {
        if (volume > startVolume) {
            const applicableVolume = Math.min(volume, endVolume) - startVolume;
            return Math.floor(applicableVolume / interval) * slope;
        }
        return 0;
    };

    totalPrice += calculateIntervalPrice(0, 300, 2000);
    totalPrice += calculateIntervalPrice(300, 500, 1500);
    totalPrice += calculateIntervalPrice(500, 1000, 1300);
    totalPrice += calculateIntervalPrice(1000, Infinity, 1200);

    return totalPrice;
};
  const [dragOver, setDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };
  
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };
  
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(e.dataTransfer.files);
    }
  };

  const removeModel = (index) => {
    setModels(prev => prev.filter((_, i) => i != index))
  }

  const inputRef = useRef(null)
  const removeFilesFromInput = () => {
    if(inputRef.current) inputRef.current.value = null
  }
  const sumVolume = useMemo(() => models.reduce(function(sum, model){
    return sum + model.volume * model.quantity * model.scale ** 3;
  }, 0)/1000, [models]);
  
  const changeModelQuantity = (index, dif) => {
    setModels(prev => prev.map((model, i) => {
      if(i === index) return {
        ...model,
        quantity: Math.max(1, model.quantity + dif)
      }
      return model
    }))
  }

const [servicePage, setServicePage] = useState(null)
  const handleFetchServicePage = () => {
    // fetch("https://api.linkpreview.net/?q=https://coconala.com/services/2939150&key=2e9c19af71ff67bed1a9241494cf27d3").then(res => {
    //   if(!res) return
    //   res.json().then((body) => {
    //     setServicePage({
    //       description: body.description,
    //       image: body.image,
    //       title: body.title,
    //       url: body.url
    //     })
    //   })
    // })
    setServicePage({
      description: "1. すでに3Dモデルがありそれを印刷したい。2. 作りたい製品の設計・寸法が決まっているが3Dモデルがなく一から作成したい。3. 既存の製品を複製したい。4. 既存の製品のサイズを大きく、または小さくして複製したい。5. 既存製品をカスタマイズするための外付けパーツを作成したい",
      image: "https://service-cdn.coconala.com/resize/1220/1240/service_images/original/f419d5b7-6858629.png",
      title:  "3Dプリンターで3Dモデルを3Dプリント出力します",
      url: "https://coconala.com/services/2939150"
    })
  }

  useEffect(() => {
    handleFetchServicePage()
  }, [])

  return (
    <>
    <header className={styles["header"]}>
    <div className={styles["brand-name"]}>
    <h1 className={styles["pc-title"]}>3Dプリンター出力サービス【個人・法人向け】</h1><h2 className={styles["pc-title"]}> - ものづくり制作ラボ</h2>
    <h1 className={styles["mobile-title"]}>ものづくり制作ラボ</h1>
    </div>
  </header>
  <div className={styles["wrapper"]}>
  {
    servicePage ? (
      <a className={styles['service-page']} href={servicePage.url} target="_blank" rel="noreferrer" >
        <div className={styles['service-page-scale-wrapper']}>
      <h1 className={styles['service-page-lead']}>▽出力のご依頼はこちらから▽</h1>
      <img src={servicePage.image} alt="サービスページ"/>
      <p className={styles['service-page-title']}>{servicePage.title.split(" ")[0]}</p>
      <p className={styles['service-page-description']}>{servicePage.description}</p>
      <button className={styles["service_button"]}>▶︎ お仕事を依頼する ◀︎</button>
      </div>
    </a>
    ): null
  }
    <div className={styles["upload-container"]}>
   <h1 className={styles["page-title"]}>3Dモデルをアップロードして<br className={styles["mobile"]}/>簡単見積もり</h1>
    <div
      className={(dragOver ? [styles['dropzone'], styles['dragover']] : [styles['dropzone']]).join(" ")}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onClick={() => inputRef.current?.click()}
    >
      <p style={{ fontWeight: "bold", color: models.length ? "inherit" : "#ff4d4f" }}>ここにSTL・OBJファイルを追加※複数ファイル対応</p>
      <UploadFileIcon style={{ width: 100, height: 100 }}/>
      <input
        ref={inputRef}
        id="fileInput"
        multiple
        type="file"
        onChange={e => handleFileChange(e.target.files)}
        accept=".stl,.obj"
        className={styles["fileInput"]}
        style={{ display: 'none' }}
      />
    </div>
    {models.length ? <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center', flexWrap: "wrap" }}>
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center'}}>
      <input type="checkbox" value={checkedPortfolio} onChange={(e) => setCheckedPortfolio(e.target.checked)}/>
      <p style={{ color: "gray", textAlign: "center", margin: 0 }}>ポートフォリオ及びサービス画像への掲載を許可する</p>
      </div> */}
       {/* <p style={{ color: "#ff4d4f", textAlign: "center", margin: 0,fontSize: 18, fontWeight: "bold" }}>※先着10名限定{`${discount*100}`}%OFF！！</p> */}
       
    </div>: null}
      {models.length ? <div className={styles["volume-price"]}>
        <p>{`見積価格: ${price(sumVolume*1.5)}円`}</p>
      </div> : null }
      
      {models.length ? <div className={styles["dimensions-area"]}>
          {models.map((model, index) => (
            <>
            <div key={index} className={styles["model-item"]}>
              <p className={styles["model-name"]}>{`${toCircled(index + 1)} ${model.name}`}</p>
              <div className={styles["modal-right"]}>
                <div className={styles["model-quantity"]}>
                  印刷数: 
                <button onClick={() => changeModelQuantity(index, -1)}>-</button>
                <span>{model.quantity}</span>
                <button onClick={() => changeModelQuantity(index, 1)}>+</button>
              </div>
              <button onClick={() => removeModel(index)} className={styles["remove-button"]}>取り消し</button>

              </div>
            </div>
            <div className={styles["dimensions-info"]}>
              <div className={styles["dimensions"]}>
              <p className={styles["pc"]}>{"寸法　"}</p>
              <p>x: {model.dimensions.width * model.scale }mm y: {model.dimensions.height * model.scale}mm z: {model.dimensions.depth * model.scale}mm </p>
              </div>
              <div className={styles["scale"]}>
              <div>拡大率</div>
              <input type="number" value={model.scale} onChange={(e) => setModels(prev => prev.map((m, i) => i === index ? ({ ...m, scale: e.target.value }) : m ))} onBlur={() => setModels(prev => prev.map((m, i) => i === index ? ({ ...m, scale: Math.max(m.scale, 1) }) : m ))}></input>
              </div>
            </div>
            </>
          ))
        }
      </div> : null}
  </div>
  </div>
  </>
  );
};

// fileが残っているので終わったら消す

function toCircled(num) {
  if (num <= 20) {
    const base = '①'.charCodeAt(0);
    return String.fromCharCode(base + num - 1);
  }
  if (num <= 35) {
    const base = '㉑'.charCodeAt(0);
    return String.fromCharCode(base + num - 21);
  }
  const base = '㊱'.charCodeAt(0);
  return String.fromCharCode(base + num - 36);
}